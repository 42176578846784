.interation-container{
    height: 100%;
    position: relative  ;

    .bottom-container{
        width : 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
        
    }
    .small-card-container{
        width : 100%;
        display: flex;
        flex-wrap: wrap;
    }
}
.message-wrapper{
    width :100%;
    height : calc(100% - 70px);
   
    @include flexBox(column,flex-end, center);

    .message-box{
    
        width : calc(100% - 20px);
       
        margin: 10px;
        @include flexBox(row, center, flex-end);

        .img-label{
            height :60px;
            width : 60px;
            border-radius: 50%;
        
            margin :3px;
            img{
                height: 100%;
                width : 100%;
                margin: 0px;
            }

        }
        .message{
            width : calc(100% - 60px);
            height : 100%;
            border: 1px solid  gray;
            border-radius: 30px 30px 30px 0px;
            @include flexBox(column, flex-start, flex-start);
            padding: 10px;
            padding-left: 15px;

        }
        .chat-name{
            font-weight: 700;
        }
        .agent-message{
            width : calc(100% - 60px);
            height : 100%;
            border: 1px solid  gray;
            border-radius: 30px 30px 0px 30px;
            @include flexBox(column, flex-start, flex-start);
            background-color: #FFE3BA;
            padding: 10px;
            padding-left: 15px;
            
            // p{
            //     margin :10px;
            // }

        }
    }
}
.chat-header{
    @include flexBox(row, flex-start, center);
    width : 100%;
    height: 70px;
    background-color: #F2F4FF;
    .close{
        margin : 0px 10px;

    }
    .chat-label{
        font-size: 18px;
        margin : 0px 5px;
        color: #223189;
        font-weight: 500;
    }
}
.overlay {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    &.activeOverlay {
      opacity: 1 !important;
      pointer-events: all !important;
    }
  }
  
.side__slider__header__label__wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    z-index: 8;
    background-color: $slider-header-bg;
  
    .side__slider__close {
      cursor: pointer;
  
      .bi-x {
        position: relative;
        top: 1px;
        font-size: 1.3rem;
        margin-right: 0.5rem;
      }
    }
  
    .standard-page--label {
      color: $moderate-blue;
      font-weight: $font-weight700;
    }
  
    .side__slider__sub__heading {
      @include sub-text();
      color: $moderate-blue;
      font-weight: $font-weight600;
      margin-left: auto;
    }
  }