.small-card-wrapper {
  border-radius: 10px;
  margin-right:1.2rem;
  margin-bottom:1.5rem;
  background-color: $white-color;
  padding:1rem 1rem;
  box-shadow: $container-box-shadow;
  min-width: 220px;
  &:hover {
    cursor: pointer;
  }

  .person-name {
    font-size: $font-size100;
    font-family: $manrope-font;
    font-weight: $font-weight800;
    &::first-letter {
      text-transform:capitalize;
  }

  }
  .date-time {
    @include flexBox(row, flex-start, center);
    color: #a4a4a4;
    font-size: 16px;
    margin: 5px 0px;
  }
}
