.card-wrapper{
    height: 200px;
    width : 298px;
    border : 1px solid gray;
    border-radius: 10px;
    padding : 10px;
    background-color: $white-color;
    .count{
        // font-size: $font-size100;
        font-size: 20px;
        font-family: $manrope-font;
        font-weight: $font-weight800;
        margin: 1rem 0rem;
    }
    .card-title{
        font-size: $font-size95;
        font-weight:$font-weight600;
        font-family: $manrope-font;

    }
    .card-tag{
        font-size: 17px;
        margin : 5px 0px;
    }
}