.script-container {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 2rem;
  padding: 1rem;
  background-color: $white-color;
  .script-wrapper {
    border-radius: 10px;
    height: 80%;
    background-color: #e9e9e9;
    @include flexBox(row, center, center);
    .script-content {
      width: calc(100% - 40px);
      margin: 20px;
    }
  }
}
.copy-btn {
  background-color: #223189;
  height: 50px;
  width: 100px;
  margin-top: 20px;
  color: white;
  font-size: 18px;
  border-radius: 10px;
  &:hover {
    box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.2),
      0px 2px 4px rgba(0, 0, 0, 0.2);
  }
}
