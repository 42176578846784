// styles for selecting a property

// Updated css style for new select property screen
.select__property__wrapper {
    position: relative;
    top: 10.625rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 85%;
    margin-left: 2.5rem;

    .select__property__text {
        @include main-text();
        color: $white-color;
    }

    .select__property__sub__text {
        @include normal-text();
        color: $light-soft-blue;
        font-weight: $font-weight300;
        margin-top: 0.5rem;
    }
}


.max__height__propert__list {
    max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1rem;
    padding: 0 2rem 0 0.5rem;

    .div__property__wrap {
        width: 360px;
        max-width: 360px;
        background-color: $white-color;
        border-radius: $border-radius12px;
        box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18), 0px 4px 8px rgba(97, 97, 97, 0.18);
        margin: 1rem 0rem 2rem 0rem;
        padding: 1rem;

        .div__property__list {
            @include center-div-flex();
            justify-content: space-between;

            .property--image {
                width: 53px;
                height: 53px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: $border-radius50per;
                }
            }

            .add__new__property {
                @include center-div-flex();
                width: 53px;
                height: 53px;
                background-color: $location-marker-gray;
                border-radius: $border-radius50per;

                i {
                    font-size: 1.8rem;
                }
            }

            .peoperty__name__wrapper {
                width: 200px;
                max-width: 200px;

                .property--name {
                    @include normal-text();
                }

                .property--address {
                    @include sub-text();
                    font-weight: $font-weight400;
                }
            }

            .select__property {
                @include center-div-flex();
                width: 40px;
                height: 40px;
                border-radius: $border-radius50per;
                background-color: $very-light-gray;
                color: $moderate-blue;
                transition: all 0.236s ease-in-out;

                &:hover {
                    cursor: pointer;
                    color: $white-color;
                    background-color: $moderate-blue;
                    box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2), 0px 2px 4px rgba(15, 86, 179, 0.2);
                }
            }
        }
    }
}