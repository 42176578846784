.nav-wrapper {
  width: 100%;
}
.leads-nav-container {
  @include flexBox(row,space-between, center);
  gap: 1rem;
  .leads-drop-continer {
    @include flexBox(row, space-between, center);
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 20px;
    .leads-dropDown {
      border: 1px solid gray;
      @include flexBox(row, center, center);
      height: 40px;
      color: #223189;
    }
    .add__background {
      background-color: $white-color;
      width: 200px;
    }
    .leads__select__date__wrapper {
       display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 0.658rem;
    margin-bottom: 2.6rem;
    border: 2px solid #C0C0C0;
    border-radius: 8px;
    cursor: pointer;
    background-color: #fff;

    .date__picker__icon {
      position: absolute;
      left: 0;
      margin-left: 0.658rem;
      color: #223189;
    }

    .DateRangePickerInput_arrow {
      display: none;
    }

    .date__label {
      @include normal-text();
      font-weight: $font-weight400;
      z-index: 1;
    }

    .DateRangePicker {
      .DateInput_input {
        &::-webkit-input-placeholder {
          color: transparent;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: transparent;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: transparent;
        }

        &:-ms-input-placeholder {
          color: transparent;
        }
      }
    }
    }
    .MuiFormControl-root {
      background-color: $white-color !important;
    }
  }
}
.leads-buttons-container{
  display: flex;
  gap: 1rem;
  .download-btn {
    background-color: #223189;
    color: white;
    height: 40px;
    @include flexBox(row, center, center);
  }

}


.pagination-container {
  margin: 25px 0px;
  @include flexBox(row, space-between, center);
  // position: absolute;
  // bottom: 10px;
  // width : calc(100% - 350px);
  .next-nums {
    display: flex;
    align-items: center;

    .nums {
      border-radius: 50%;
      border: 1px solid gray;
      height: 40px;
      width: 40px;
      @include flexBox(row, center, center);
      margin-right: 10px;
      &:hover {
       cursor: pointer;
      }
    }
  }
  .action-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    .paginaion-btn{
      background-color: rgba(230, 230, 230, 0.5);
    @include center-div-flex();
      height: 40px;
      width: 40px;
      border-radius: 50%;
      &:hover {
        cursor: pointer;
      }
     
    }
  }
}

.checkin_dates_wrapper {
  .check_in_out_label {
    color: $dark-gray;
    font-weight: $font-weight500;
    font-size: 16px;
  }
  .bookings__select__date__wrapper {
    position: relative;
    min-width: 300px;
    margin-bottom: 0%;
    .date__picker__icon {
      position: absolute;
      left: 0;
      margin-left: 0.658rem;
      color: #223189;
    }
    .DateRangePicker_picker {
      left: 0px !important;
      z-index: 3 !important;
    }
  }
}
.list-view-table-wrapper {
  width: 100%;
  height: 100px;

  .bi-caret-down-fill,
  .bi-caret-up-fill {
    font-size: 14px;
  }

  .table {
    background-color: white;
    border-radius: $border-radius12px;
    .booking--loader {
      @include center-div-flex();
      min-height: 400px;
    }

    .listview__table__heading {
      background-color: $bg-color;
      color: $lighter-gray;

      th {
        font-weight: $font-weight600 !important;

      }
    }

    .listview__table__data {
      background-color: $white-color;
      border-top-left-radius: $border-radius8px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      td {
        vertical-align: middle;
        .email__ph{
          padding: .5rem;
          cursor: pointer;
          &:hover{
            color: $moderate-blue;
          }
        }
      }
    }
  }

  .table-borderless > :not(caption) > * > * {
    border-bottom: 8px solid $bg-color;
  }
}
