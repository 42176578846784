.sidebar-main-wrapper {
    width: 250px;
    overflow-x: scroll;
  }
  
  // .sidebar-border-right {
  //   border-right: 1px solid transparent;
  // }
  
  .sidebar-position {
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: 100%;
    box-shadow: none;
    background-color: $moderate-blue;
    overflow-y: auto;
    z-index: 1210;
    outline: 0px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-overflow-scrolling: touch;
  }
  
  .sidebar-wrapper {
    width: 250px;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  
    .company__logo__wrapper {
      @include center-div-flex();
      justify-content: flex-start;
  
      .company--logo {
        width: 36px;
        height: 36px;
        margin: 1rem 0.75rem 1rem 1.3rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjM5MTkgNy4xMjI4OEMxMi45ODYxIDQuMzQ1NDcgOC42MTMxMiA1LjA4OTY2IDguNjEzMTIgNS4wODk2NkM2LjU1MjkyIC0wLjkwMzY5OCAzLjUyMjQzIDAuMDUzMTEyNyAzLjUyMjQzIDAuMDUzMTEyN0MyLjAzMzc2IDAuMjI1ODcgMS4xOTYzOSAxLjcyNzUzIDEuMTk2MzkgMS43Mjc1M0MtMS44ODcyNyA4LjY3NzcgMS45NTQwMiAxNS45NDY4IDEuOTU0MDIgMTUuOTQ2OEMyLjIwNjU2IDE2LjY5MSAyLjUxMjI2IDE3LjQwODYgMi44ODQ0MyAxOC4wODYzQzQuMjkzMzQgMjAuNjUxMSA2LjQ4NjQ2IDE5Ljg5MzYgNi40ODY0NiAxOS44OTM2QzkuMjUxMTIgMTkuMzg4NyA4Ljg3ODk2IDE5LjM0ODggMTAuODA2MiAxOC44MDM5QzEwLjkyNTkgMTguNzY0MSAxMS44ODI5IDE4LjM3ODcgMTIuMjk0OSAxOC4yMDU5QzEzLjQyNDcgMTcuNzI3NSAxNC40ODggMTYuNzg0IDE0LjQ4OCAxNi43ODRDMTkuNTY1NCAxMS42MDEzIDE1LjM5MTkgNy4xMjI4OCAxNS4zOTE5IDcuMTIyODhaTTExLjE2NTEgMTMuNjA3OUMxMC4wMzUzIDEzLjQ2MTggOS4yMzc4MyAxMi40MjUyIDkuMzg0MDQgMTEuMjgyM0M5LjUzMDI0IDEwLjE1MjggMTAuNTY3IDkuMzU1NDQgMTEuNzEwMSA5LjUwMTYyQzEyLjgzOTkgOS42NDc4IDEzLjYzNzQgMTAuNjg0MyAxMy40OTEyIDExLjgyNzJDMTMuMzMxNyAxMi45NzAxIDEyLjI5NDkgMTMuNzY3NCAxMS4xNjUxIDEzLjYwNzlaIiBmaWxsPSIjNTI2OUNFIi8+Cjwvc3ZnPgo=");
        @include background-image-property();
        cursor: pointer;
        background-size: 23px 26px;
        border-radius: $border-radius10px;
        background-color: $white-color;
      }
    }
  
    .sidebar__list__wrapper {
      position: relative;
      margin-top: 1.5rem;
  
      .sidebar__list__items {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: $font-weight600;
        color: $white-color;
        padding: 0.65rem 0.65rem 0.65rem 1.3rem;
        margin-bottom: 0.4rem;
        transition: background-color 300ms;
  
        &:hover {
          cursor: pointer;
          color: $white-color;
          background-color: $sidebar-menu-hover-color;
  
          >.sidenav__icon__wrapper {
            >.sidenav--menu--icons {
              color: $white-color  !important;
            }
          }
        }
  
        .sidenav__icon__wrapper {
          position: relative;
          width: 24px;
          height: 24px;
          margin-right: 1.375rem;
  
          .sidenav--menu--icons {
            display: inline-block;
            @include center-div-position();
            color: $white-color;
            font-size: 1.3rem;
          }
        }
  
        .sidenav__arrow {
          margin-left: auto;
          padding-right: 0.67rem;
        }
      }
  
      .sidebar__list__items__active {
        color: $white-color;
        background-color: $sidebar-menu-hover-color;
  
        >.sidenav__icon__wrapper {
          >.sidenav--menu--icons {
            color: $white-color  !important;
          }
        }
      }
  
      .sidebar__dropdown__wrapper {
        margin-left: 3.5rem;
        overflow: hidden;
  
        .sidebar__dropdown__list {
          font-size: $font-size95;
          font-weight: $font-weight500;
          color: $white-color;
          padding: 0.15rem 0.65rem 0.15rem 1.5rem;
          margin: 0.5rem 0rem;
          border-left: 3px solid $transparent;
  
          &:hover {
            cursor: pointer;
            color: $white-color;
            border-radius: $border-radius4px;
          }
        }
  
        .sidebar__dropdown__active {
          color: $white-color;
          font-weight: $font-weight500;
          border-left: 3px solid $white-color;
          border-radius: $border-radius4px;
        }
      }
  
      .sidebar__dropdown__show {
        max-height: 100%;
        transition: max-height 1s ease-in 0s;
  
        // transition: max-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
  
      .sidebar__dropdown__hide {
        max-height: 0%;
        transition: 0.8s;
  
        //   transition: max-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
  
      .icon__rotate__180 {
        transform: rotate(180deg);
        transition: transform 500ms;
      }
  
      .icon__rotate__360 {
        transform: rotate(360deg);
        transition: transform 500ms;
      }
    }

    // user profile div
    .user__profile__div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      background-color: $white-color;
      cursor: pointer;
  
      .user__profile__info {
        display: flex;
        width: 75%;
        color: $primary-btn-color;
        margin-left: 1rem;
  
        .user__name {
          font-weight: $font-weight700;
        }
  
        &:hover {
          color: $moderate-blue;
        }
      }
  
      .hotel__logout {
        @include center-div-flex;
        width: calc(100% - 75%);
        height: 100%;
        background-color: $light-pale-red;
  
        .logout-icon-size {
          color: $strong-red;
          font-size: 1rem;
          font-weight: $font-weight600;
          transition: all .1s ease-in-out;
  
          &:hover {
            transform: scale(1.455);
          }
        }
      }
    }
  }
  
  // New menu option style
  .new__menu__option {
    min-width: 50px;
    @include sub-text();
    color: $moderate-blue;
    font-weight: $font-weight600;
    text-align: center;
    padding: 0.124rem;
    margin-left: 1rem;
    background-color: $very-soft-blue;
    border-radius: $border-radius26px;
  }